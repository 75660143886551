<script>
import CustomFieldForm from "@/views/menu/configurations/customFields/customFieldForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    customFieldService: "customFieldService",
    fileUploadService: "fileUploadService",
    toastService: "toastService",
  },
})
export default class CustomFieldEdit extends mixins(CustomFieldForm) {
  formId = "edit-customField-form";

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        disabled: () => {
          return this.fileUploadService.isUploading();
        },
        handle: this.save,
      },
    ];
  }
  async save(action, passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
      if (this.hasOptionsList()) {
        const selectValues = JSON.parse(this.model.fieldOptions)?.values;
        if (
          selectValues?.length > 1 &&
          selectValues?.filter((val) => val === "")?.length > 1
        ) {
          throw {
            message: "More then 1 selectable values are empty",
          };
        }
      }

        await this.customFieldService.update(
        this.model.id,
        this.model
      );
      this.$router.push("/configurations/custom-fields/list");
      this.toastService.success(this.translations.success.customField_update);
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  getFormTitle() {
    return this.translations.pageTitles.customFields_edit;
  }

  async afterCreate() {
    this.model = await this.customFieldService.read(this.$route.params.id);
    this.model.selectAdvancedFieldOptions =
      this.prepareSelectAdvancedFieldOptions();
    this.model.textAdvancedFieldOptions =
      this.prepareTextAdvancedFieldOptions();
    this.model.numberAdvancedFieldOptions =
      this.prepareNumberAdvancedFieldOptions();
    this.isReady = true;
  }
}
</script>
